import React from "react";

import { Grid, Cell, Button } from "../../components/ui";
import {
  FormattedContentItem,
  ContentItem,
  ContentObject,
} from "../../components/content";

import { useStyletron } from "baseui";
import {
  LabelLarge,
  ParagraphLarge,
  LabelSmall,
  HeadingLarge,
  LabelXSmall,
  DisplaySmall,
} from "baseui/typography";

import { AspectRatioBox, AspectRatioBoxBody } from "baseui/aspect-ratio-box";

import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";
import atendeLogo from "../../assets/images/atende.svg";
import atendeWhiteLogo from "../../assets/images/atende-white.svg";
import investorPhoto from "../../assets/images/investor.jpg";

import Social from "../../assets/images/social";
import { useTheme } from "../../contexts/theme";

const Investor = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  return (
    <article data-csweb="about-us-investor">
      <div
        className={css({
          marginTop: theme.sizing.scale200,
          marginBottom: theme.sizing.scale0,
          position: "relative",
        })}
      >
        <Grid>
          <Cell span={[4, 4, 6]}>
            <DisplaySmall
              $style={{
                textAlign: "right",
                [theme.breakpoints.mediaQueries.medium]: {
                  textAlign: "center",
                  marginBottom: theme.sizing.scale600,
                },
                [theme.breakpoints.mediaQueries.small]: {
                  ...theme.typography.DisplayXSmall,
                },
              }}
            >
              <FormattedContentItem
                scope="AboutUs"
                accessor="investor.heading"
              />
            </DisplaySmall>
          </Cell>

          <Cell span={[4, 4, 4]} align="center">
            <img
              alt="Dot grid background"
              className={css({
                position: "absolute",
                marginTop: "-380px",
                marginLeft: "-150px",
                zIndex: "1",
                pointerEvents: "none",
              })}
              src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
            />
            <div
              className={css({
                textAlign: "center",
              })}
            >
              <img
                alt="Atende"
                src={useDarkTheme ? atendeWhiteLogo : atendeLogo}
                height="42px"
                className={css({
                  [theme.breakpoints.mediaQueries.small]: {
                    height: "30px",
                    marginTop: theme.sizing.scale400,
                  },
                })}
              />
            </div>
          </Cell>
        </Grid>
      </div>
      <div
        className={css({
          position: "relative",
          overflow: "hidden",
          marginTop: theme.sizing.scale4800,
          marginBottom: theme.sizing.scale4800,
          ":hover [data-csweb=img-photo]": {
            filter: "grayscale(0)",
            opacity: "1",
          },
          ":hover [data-csweb=box-gradient]": {
            top: "20%",
          },
          ":hover p": {
            marginTop: theme.sizing.scale1000,
            paddingBottom: theme.sizing.scale800,
          },
          ":hover [data-csweb=typo-label]": {
            marginBottom: theme.sizing.scale600,
          },
          [theme.breakpoints.mediaQueries.medium]: {
            marginTop: theme.sizing.scale1200,
            marginBottom: theme.sizing.scale1200,
          },
          [theme.breakpoints.mediaQueries.small]: {
            ":hover p": {
              marginTop: theme.sizing.scale1200,
              paddingBottom: theme.sizing.scale900,
            },
            ":hover [data-csweb=typo-label]": {
              marginBottom: theme.sizing.scale700,
            },
          },
        })}
      >
        <Grid>
          <Cell skip={[0, 0, 2]} span={[4, 5, 5]} align="center">
            <div
              className={css({
                position: "relative",
                zIndex: "4",
              })}
            >
              <HeadingLarge
                $style={{
                  marginTop: theme.sizing.scale1200,
                  marginBottom: theme.sizing.scale800,
                  [theme.breakpoints.mediaQueries.medium]: {
                    marginTop: theme.sizing.scale600,
                  },
                  [theme.breakpoints.mediaQueries.small]: {
                    ...theme.typography.HeadingMedium,
                  },
                }}
              >
                <ContentItem scope="AboutUs" accessor="investor.subheading" />
              </HeadingLarge>
              <ParagraphLarge
                $style={{
                  marginTop: theme.sizing.scale1200,
                  paddingBottom: theme.sizing.scale900,
                  transitionProperty: "all",
                  transitionDuration: "200ms",
                  transitionTimingFunction: "ease-in-out",
                  [theme.breakpoints.mediaQueries.medium]: {
                    marginTop: theme.sizing.scale600,
                  },
                }}
              >
                <FormattedContentItem
                  scope="AboutUs"
                  accessor="investor.text"
                />
              </ParagraphLarge>
              <div
                className={css({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  [theme.breakpoints.mediaQueries.small]: {
                    flexDirection: "column",
                  },
                })}
              >
                <div>
                  <LabelLarge
                    color="primary"
                    $style={{
                      fontSize: "28px",
                      letterSpacing: "2px",
                      fontWeight: "700",
                    }}
                  >
                    <ContentItem
                      scope="AboutUs"
                      accessor="investor.author.name"
                    />
                  </LabelLarge>
                  <LabelSmall
                    data-csweb="typo-label"
                    $style={{
                      marginTop: theme.sizing.scale200,
                      textTransform: "uppercase",
                      letterSpacing: "2px",
                      fontWeight: "400",
                      transitionProperty: "all",
                      transitionDuration: "200ms",
                      transitionTimingFunction: "ease-in-out",
                      [theme.breakpoints.mediaQueries.small]: {
                        marginBottom: theme.sizing.scale700,
                        textAlign: "center",
                      },
                    }}
                  >
                    <ContentItem
                      scope="AboutUs"
                      accessor="investor.author.title"
                    />
                  </LabelSmall>
                </div>
                <div
                  className={css({
                    display: "flex",
                    alignItems: "flex-end",
                  })}
                >
                  <LabelXSmall
                    $style={{
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      lineHeight: "15px",
                      marginRight: theme.sizing.scale600,
                    }}
                  >
                    <FormattedContentItem
                      scope="AboutUs"
                      accessor="investor.author.linkedInLabel"
                    />
                  </LabelXSmall>
                  <Button
                    kind="circle"
                    $as={({ children, ...props }) => (
                      <a
                        href={ContentObject.AboutUs.investor.author.linkedInUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        {...props}
                      >
                        {children}
                      </a>
                    )}
                  >
                    <img
                      alt="LinkedIn"
                      src={Social.LinkedIn}
                      width="16px"
                      height="16px"
                    />
                  </Button>
                </div>
              </div>
            </div>
          </Cell>

          <Cell span={[0, 3, 4]}>
            <div
              className={css({
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                flexFlow: "row nowrap",
                zIndex: "4",
                [theme.breakpoints.mediaQueries.small]: {
                  display: "none",
                },
              })}
            >
              <AspectRatioBox
                aspectRatio={2 / 3}
                width={"320px"}
                overflow="hidden"
              >
                <AspectRatioBoxBody
                  data-csweb="img-photo"
                  as="img"
                  src={investorPhoto}
                  $style={{
                    transitionProperty: "all",
                    transitionDuration: "200ms",
                    transitionTimingFunction: "ease-in-out",
                    filter: "grayscale(1)",
                    opacity: "0.6",
                  }}
                />
              </AspectRatioBox>
              <div
                data-csweb="box-gradient"
                className={css({
                  alignSelf: "flex-start",
                  position: "relative",
                  top: "0%",
                  maxWidth: "100px",
                  transitionProperty: "all",
                  transitionDuration: "200ms",
                  transitionTimingFunction: "ease-in-out",
                  height: "80%",
                  flexGrow: "1",
                  background:
                    "linear-gradient(0deg, rgba(0,220,230,1) 0%, rgba(60,0,220,1) 100%)",
                })}
              ></div>
            </div>
          </Cell>
          <Cell skip={[2, 3, 5]} span={[2, 5, 7]}>
            <div
              data-csweb="bg-graybox"
              className={css({
                position: "relative",
                transitionProperty: "all",
                transitionDuration: "200ms",
                transitionTimingFunction: "ease-in-out",
                width: "100vw",
                height: "260px",
                marginTop: "-150px",
                zIndex: "0",
                opacity: "0.3",
                backgroundColor: theme.colors.backgroundLightAccent,
                [theme.breakpoints.mediaQueries.small]: {
                  marginTop: "-230px",
                  marginLeft: "-32px",
                  width: "105vw",
                },
              })}
            ></div>
          </Cell>
        </Grid>
      </div>
    </article>
  );
};

export default Investor;
