import React from "react";

import { useStyletron } from "baseui";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Video from "./about-us-video";
import Timeline from "./about-us-timeline";
import Panels from "./about-us-panels";
// import Team from "./about-us-team";
import Investor from "./about-us-investor";
import JoinTeam from "./about-us-join-team";

const AboutUs = () => {
  const [css] = useStyletron();

  return (
    <>
      <SEO
        title={ContentObject.AboutUs.meta.title}
        description={ContentObject.AboutUs.meta.description}
      />

      <Layout
        header={{ color: "white", position: "absolute" }}
        footer={{ size: "compact" }}
        title="AboutUs"
      >
        <section
          data-csweb="about-us"
          className={css({
            animationFillMode: "both",
            animationDuration: "400ms",
            animationName: {
              "0%": {
                opacity: "1",
                transform: "translateY(0px)",
              },
              "100%": {
                opacity: "1",
                transform: "translateY(0px)",
              },
            },
          })}
        >
          <Video />
          <Timeline />
          <Panels />
          {/* <Team /> */}
          <Investor />
          <JoinTeam />
        </section>
      </Layout>
    </>
  );
};

export default AboutUs;
